<ng-scrollbar style="height: calc(100vh - 70px)" visibility="hover">
  <div class="navbar-content">
    <ul class="nav coded-inner-navbar" (clickOutside)="fireOutClick()" (mouseleave)="navMob()">
      @for (item of navigation; track item) {
        @if (item.type == 'group') {
          <app-nav-group [item]="item"></app-nav-group>
        }
        @if (item.type == 'collapse') {
          <app-nav-collapse [item]="item"></app-nav-collapse>
        }
        @if (item.type == 'item') {
          <app-nav-item [item]="item"></app-nav-item>
        }
      }
    </ul>
    <div class="pc-navbar-card bg-primary rounded">
      <h4 class="text-white">Berry Pro</h4>
      <p class="text-white">Checkout Berry Premium Features</p>
      <a
        href="https://codedthemes.com/item/berry-angular-admin-dashboard-template/?utm_source=free_demo&utm_medium=codedthemes&utm_campaign=button_download_premium"
        target="_blank"
        class="btn btn-light text-primary"
      >
        Download Premium
      </a>
    </div>
    <div class="version">
      <label disabled class="pe-auto">v{{ currentApplicationVersion }}</label>
    </div>
  </div>
</ng-scrollbar>
